import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadproforma(payload) {
  return apiClient
    .post(`${BASE_URL}/loadproforma`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createProforma(payload) {
  return apiClient
    .post(`${BASE_URL}/createProforma/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadaccountdetail(payload) {
  return apiClient
    .post(`${BASE_URL}/loadaccountdetail`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changeaccountsassignedto(payload) {
  return apiClient
    .post(`${BASE_URL}/changeaccountsassignedto`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsinglequotation(payload) {
  return apiClient
    .post(`${BASE_URL}/singleaccounts`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getdesigninfo(payload) {
  return apiClient
    .post(`${BASE_URL}/getdesigninfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadquotationnumber(payload) {
  return apiClient
    .post(`${BASE_URL}/loadquotationnumber`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploaddesign(payload) {
  return apiClient
    .post(`${BASE_URL}/uploaddesign`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteimage(payload) {
  return apiClient
    .post(`${BASE_URL}/deletesliderinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function exportproforma(payload) {
  return apiClient
    .post(`${BASE_URL}/exportproforma/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadfollowup() {
  return apiClient
    .get(`${BASE_URL}/loadquotationfollowup/`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatefollowup(payload) {
  return apiClient
    .post(`${BASE_URL}/updatefollowup/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
