import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Form, Button, Upload, message, Select } from 'antd'
import { injectIntl } from 'react-intl'
import { createStructuredSelector } from 'reselect'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { selectSettings } from 'redux/settings/selectors'
import { selectSingleAccounts } from 'redux/singleaccounts/selectors'
import { selectUser } from 'redux/user/selectors'
import SingleAccountsAction from 'redux/singleaccounts/actions'
import CardTitle from 'components/app/common/CardTitle'

const { Option } = Select

const mapStateToProps = () =>
  createStructuredSelector({
    singleAccount: selectSingleAccounts,
    settings: selectSettings,
    user: selectUser,
  })

const AddSliderImage = ({ singleAccount, intl: { formatMessage } }) => {
  const dispatch = useDispatch()

  const path = window.location.pathname

  const idval = path.replace('/manage/accounts/', '')

  useEffect(() => {
    if (idval !== null)
      dispatch({
        type: SingleAccountsAction.GETQUOTATIONNUMBER,
        payload: idval,
      }) // eslint-disable-next-line
  }, [dispatch])

  const [loading, setLoading] = React.useState(false)
  const [image, setImage] = React.useState(null)
  const [imageUrl, setImageUrl] = React.useState(null)

  const getCategoryvalues = values => {
    const ImagetypeInfo = values.field.file.type
    dispatch({
      type: SingleAccountsAction.UPLOADIMAGE,
      payload: {
        quotation_id: values?.quotation_id,
        imageUrlPath: imageUrl,
        imageData: image,
        ImageType: ImagetypeInfo,
      },
    })
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const [form] = Form.useForm()

  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setLoading(false)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl1 => {
        setImage(info.file.name)
        setImageUrl(imageUrl1)
        setLoading(false)
      })
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const beforeUpload = file => {
    const isJpgOrPng =
      file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const dummyRequest = ({ file, onSuccess }) => {
    console.log(file)
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={getCategoryvalues}
            form={form}
            enctype="multipart/form-data"
          >
            <CardTitle title={formatMessage({ id: 'text.uploadDesign' })} />
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="quotation_id"
                  label={formatMessage({ id: 'form.label.quotationNumber' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    placeholder={formatMessage({ id: 'form.placeholder.quotationNumber' })}
                    size="large"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {singleAccount?.quotationInfo?.map(o => {
                      return (
                        <Option key={o.quotation_number} value={o.quotation_number}>
                          {`${o.business_name} - ${o.quotation_number}`}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="Upload file for Quotation Design" name="field">
                  <Upload
                    name="image"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    customRequest={dummyRequest}
                    onChange={handleChange}
                  >
                    {imageUrl ? (
                      <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              </div>
            </div>
            <Form.Item className="text-right">
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={singleAccount?.loading}
              >
                {formatMessage({ id: 'action.upload' })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(AddSliderImage))
