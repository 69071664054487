const actions = {
  SET_STATE: 'invoice/SET_STATE',
  LOAD_ALL: 'invoice/LOAD_ALL',
  VIEW_PO: 'invoice/VIEW_PO',
  HIDE_PO: 'invoice/HIDE_PO',
  VIEW_INVOICES: 'invoice/VIEW_INVOICES',
  HIDE_INVOICES: 'invoice/HIDE_INVOICES',
  LOAD_INVOICE_DETAIL: 'invoice/LOAD_INVOICE_DETAIL',
  LOAD_PAYMENT_DETAIL: 'invoice/LOAD_PAYMENT_DETAIL',
  ADD_ITEM: 'invoice/ADD_ITEM',
  HIDE_ITEM: 'invoice/HIDE_ITEM',
  CREATE_INVOICE_ITEM: 'invoice/CREATE_INVOICE_ITEM',
}

export default actions
