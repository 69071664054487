import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadaccountdetail,
  loadsinglequotation,
  getdesigninfo,
  loadquotationnumber,
  uploaddesign,
  deleteimage,
  loadproforma,
  createProforma,
  exportproforma,
  loadfollowup,
  updatefollowup,
} from 'api/singleaccounts'
import actions from './actions'

export function* ACCOUNT_DETAIL({ payload }) {
  const { response } = yield call(loadaccountdetail, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        accountDetailInfo: response?.data?.getaccountDetail,
        // _id: response?.data?.getaccountDetail?._id,
        // business_name: response?.data?.getaccountDetail?.business_name,
        // contact_name: response?.data?.getaccountDetail?.contact_name,
        // emailid: response?.data?.getaccountDetail?.emailid,
        // mobile: response?.data?.getaccountDetail?.mobile,
        // phone: response?.data?.getaccountDetail?.phone,
        // gst_number: response?.data?.getaccountDetail?.gst_number,
        // website_url: response?.data?.getaccountDetail?.website_url,
        // company_since: response?.data?.getaccountDetail?.company_since,
        // status: response?.data?.getaccountDetail?.status,
        // addressline1: response?.data?.getaccountDetail?.addressline1,
        // addressline2: response?.data?.getaccountDetail?.addressline2,
        // addressline3: response?.data?.getaccountDetail?.addressline3,
        // landmark: response?.data?.getaccountDetail?.landmark,
        // city: response?.data?.getaccountDetail?.city,
        // state: response?.data?.getaccountDetail?.state,
        // country: response?.data?.getaccountDetail?.country,
        // pincode: response?.data?.getaccountDetail?.pincode,
      },
    })
  }
}

export function* LOAD_PROFORMA({ payload }) {
  const { response } = yield call(loadproforma, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        proformaList: response?.data?.proformaList,
      },
    })
  }
}

export function* LOADFOLLOWUPINFO() {
  const { response } = yield call(loadfollowup)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        followupList: response?.data?.followupList,
      },
    })
  }
}

export function* UPDATE_FOLLOWUP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      adding: true,
    },
  })
  const { response } = yield call(updatefollowup, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayFollowupModal: false,
        adding: false,
        followupList: response?.data?.followupList,
      },
    })
  }
}

export function* LOAD_SINGLE_QUOTATION({ payload }) {
  const { response } = yield call(loadsinglequotation, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        getsinglebusinessquotation: response?.data?.getsinglebusinessquotation,
      },
    })
  }
}

export function* CREATE_INVOICE_ITEM({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(createProforma, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        // displayCreateInvoiceDrawer: false,
        proformaList: response?.data?.proformaList,
      },
    })
  }
}

export function* VIEW_PO({ payload }) {
  const { response } = yield call(exportproforma, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        proformapdfLocationUrl: response?.data?.proformapdfLocationUrl,
        openproforma: true,
      },
    })
  }
}

export function* GETQUOTATIONNUMBER({ payload }) {
  const { response } = yield call(loadquotationnumber, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        quotationInfo: response?.data?.quotationInfo,
      },
    })
  }
}

export function* DESIGN_INFO({ payload }) {
  const { response } = yield call(getdesigninfo, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        designInfo: response?.data?.designInfo,
      },
    })
  }
}

export function* UPLOADIMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: true,
    },
  })
  const { response } = yield call(uploaddesign, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        designInfo: response?.data?.designInfo,
      },
    })
  }
}

export function* DELETEIMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      deleting: true,
    },
  })
  const { response } = yield call(deleteimage, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      deleting: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        designInfo: response?.data?.designInfo,
        displayDeleteImagePopup: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.DELETEIMAGE, DELETEIMAGE),
    takeLatest(actions.DESIGN_INFO, DESIGN_INFO),
    takeLatest(actions.UPLOADIMAGE, UPLOADIMAGE),
    takeLatest(actions.ACCOUNT_DETAIL, ACCOUNT_DETAIL),
    takeLatest(actions.LOAD_SINGLE_QUOTATION, LOAD_SINGLE_QUOTATION),
    takeLatest(actions.GETQUOTATIONNUMBER, GETQUOTATIONNUMBER),
    takeLatest(actions.LOAD_PROFORMA, LOAD_PROFORMA),
    takeLatest(actions.CREATE_INVOICE_ITEM, CREATE_INVOICE_ITEM),
    takeLatest(actions.VIEW_PO, VIEW_PO),
    takeLatest(actions.LOADFOLLOWUPINFO, LOADFOLLOWUPINFO),
    takeLatest(actions.UPDATE_FOLLOWUP, UPDATE_FOLLOWUP),
  ])
}
