const actions = {
  SET_STATE: 'singleaccounts/SET_STATE',
  LOAD_ALL: 'singleaccounts/LOAD_ALL',
  ACCOUNT_DETAIL: 'singleaccounts/ACCOUNT_DETAIL',
  LOAD_SINGLE_QUOTATION: 'singleaccounts/LOAD_SINGLE_QUOTATION',
  DESIGN_INFO: 'singleaccounts/DESIGN_INFO',
  SHOW_CREATE: 'singleaccounts/SHOW_CREATE',
  HIDE_CREATE: 'singleaccounts/HIDE_CREATE',
  UPLOADIMAGE: 'singleaccounts/UPLOADIMAGE',
  GETQUOTATIONNUMBER: 'singleaccounts/GETQUOTATIONNUMBER',
  SHOW_POPUP: 'singleaccounts/SHOW_POPUP',
  HIDE_POPUP: 'singleaccounts/HIDE_POPUP',
  DELETE_SHOW_IMAGE: 'singleaccounts/DELETE_SHOW_IMAGE',
  DELETE_HIDE_IMAGE: 'singleaccounts/DELETE_HIDE_IMAGE',
  DELETEIMAGE: 'singleaccounts/DELETEIMAGE',
  LOAD_PROFORMA: 'singleaccounts/LOAD_PROFORMA',
  ADD_ITEM: 'singleaccounts/ADD_ITEM',
  HIDE_ITEM: 'singleaccounts/HIDE_ITEM',
  CREATE_INVOICE_ITEM: 'singleaccounts/CREATE_INVOICE_ITEM',
  VIEW_PO: 'singleaccounts/VIEW_PO',
  LOADFOLLOWUPINFO: 'singleaccounts/LOADFOLLOWUPINFO',
  SHOW_FOLLOWUP: 'singleaccounts/SHOW_FOLLOWUP',
  HIDE_FOLLOWUP: 'singleaccounts/HIDE_FOLLOWUP',
  UPDATE_FOLLOWUP: 'singleaccounts/UPDATE_FOLLOWUP',
}

export default actions
