import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { createStructuredSelector } from 'reselect'
import { selectSingleAccounts, selectQuotationInfo } from 'redux/singleaccounts/selectors'
import { SelectQuotation } from 'redux/quotation/selectors'
import { selectSettings } from 'redux/settings/selectors'
import { selectUser } from 'redux/user/selectors'
import SingleAccountsAction from 'redux/singleaccounts/actions'
import ViewDesignInfo from './ViewDesignInfo'

const mapStateToProps = () =>
  createStructuredSelector({
    singleAccount: selectSingleAccounts,
    selectedSingleQuotation: selectQuotationInfo,
    settings: selectSettings,
    quotation: SelectQuotation,
    user: selectUser,
  })

const SliderComponent = ({ intl: { formatMessage } }) => {
  const dispatch = useDispatch()

  const path = window.location.pathname

  const idval = path.replace('/manage/accounts/', '')

  useEffect(() => {
    if (idval !== null)
      dispatch({
        type: SingleAccountsAction.DESIGN_INFO,
        payload: idval,
      }) // eslint-disable-next-line
  }, [dispatch])

  return (
    <div>
      <Helmet title={formatMessage({ id: 'title.helmet.designList' })} />
      <ViewDesignInfo />
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(SliderComponent))
