import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Button, Form, Input, Space, InputNumber, Select, Descriptions } from 'antd'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import DragHandle from 'utils/drag-handle'
import SingleAccountsAction from 'redux/singleaccounts/actions'
import CardTitle from 'components/app/common/CardTitle'

const { Option } = Select
const { TextArea } = Input

const mapStateToProps = ({ singleaccounts, settings }) => ({
  singleaccounts,
  settings,
})

const CreateItem = ({ singleaccounts, intl: { formatMessage } }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [showcgst, setshowcgst] = useState(0)
  const [showigst, setshowigst] = useState(0)

  const onFinish = values => {
    const itemArray = values?.options
    const itemArrayLength = itemArray?.length
    if (itemArrayLength > 0) {
        if (values?.gst === 'intrastate') {
          dispatch({
            type: SingleAccountsAction.CREATE_INVOICE_ITEM,
            payload: {
              itemList: itemArray,
              businessId: Number(singleaccounts?.accountDetailInfo?.id),
              type: values?.invoicetype,
              cgst: values?.cgst,
              sgst: values?.sgst,
              igst: 0,
              gsttype: values?.gst,
              discount: values?.discountvalue,
              keynotes: values?.keynotes,
            },
          })
        } else {
          dispatch({
            type: SingleAccountsAction.CREATE_INVOICE_ITEM,
            payload: {
              itemList: itemArray,
              businessId: Number(singleaccounts?.accountDetailInfo?.id),
              type: values?.invoicetype,
              igst: values?.igst,
              cgst: 0,
              sgst: 0,
              gsttype: values?.gst,
              discount: values?.discountvalue,
              keynotes: values?.keynotes,
            },
          })
        }
      
    }
  }

  const onValuesChange = changedValues => {
    if (changedValues.gst === 'intrastate') {
      setshowcgst(1)
      setshowigst(0)
    } else if (changedValues.gst === 'interstate') {
      setshowigst(1)
      setshowcgst(0)
    }
  }

  const SortableItem = SortableElement(({ field, remove, index }) => {
    return (
      <div key={`element-${index}`}>
        <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
          <Form.Item
            {...field}
            name={[field.name, 'description']}
            fieldKey={[field.fieldKey, 'description']}
            rules={[{ required: true, message: 'Missing Description' }]}
          >
            <Input size="large" placeholder="Description" style={{ width: '250px' }} />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, 'item']}
            fieldKey={[field.fieldKey, 'item']}
            rules={[{ required: true, message: 'Missing Item Cost' }]}
          >
            <InputNumber
              size="large"
              prefix="$"
              min={0}
              step={0.1}
              precision={2}
              placeholder="Item Cost"
              style={{ width: '250px' }}
            />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, 'quantity']}
            fieldKey={[field.fieldKey, 'quantity']}
            rules={[{ required: true, message: 'Missing Quantity' }]}
          >
            <InputNumber size="large" placeholder="Quantity" style={{ width: '150px' }} />
          </Form.Item>
          <MinusCircleOutlined onClick={() => remove(field.name)} />
          <DragHandle />
        </Space>
      </div>
    )
  })

  const SortableList = SortableContainer(({ items, remove }) => {
    return (
      <div>
        {items.map((field, index) => (
          <SortableItem key={`item-${field}`} index={index} field={field} remove={remove} />
        ))}
      </div>
    )
  })

  const accountinfo = singleaccounts?.accountDetailInfo

  console.log('singleaccounts', singleaccounts)

  return (
    <div>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        form={form}
        onValuesChange={onValuesChange}
      >
        <div className="card">
          <div className="card-body">
            <CardTitle />
            <div className="row">
              {accountinfo?.length !== 0 && (
                <>
                  <div className="col-12 mt-3">
                    <Descriptions bordered>
                      <Descriptions.Item label="Business Info">
                        <strong>
                          {accountinfo?.business_name && (
                            <>
                              {accountinfo?.business_name}
                              <br />
                            </>
                          )}
                        </strong>
                        {accountinfo?.addressline1 && (
                          <>
                            {accountinfo?.addressline1}
                            <br />
                          </>
                        )}
                        {accountinfo?.addressline2 && (
                          <>
                            {accountinfo?.addressline2}
                            <br />
                          </>
                        )}
                        {accountinfo?.addressline3 && (
                          <>
                            {accountinfo?.addressline3}
                            <br />
                          </>
                        )}
                        {accountinfo?.city && <>{accountinfo?.city}</>}
                        {accountinfo?.pincode && (
                          <>
                            {accountinfo?.pincode}
                            <br />
                          </>
                        )}

                        {accountinfo?.state && (
                          <>
                            {accountinfo?.state}
                            {','}
                          </>
                        )}
                        {accountinfo?.country && (
                          <>
                            {accountinfo?.country}
                            <br />
                          </>
                        )}
                        {accountinfo?.gst_number && <>{accountinfo?.gst_number}</>}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>

                  <div className="col-12 mt-3">
                    <Form.List name="options">
                      {(fields, { add, remove, move }) => (
                        <>
                          <SortableList
                            items={fields}
                            remove={remove}
                            onSortEnd={e => move(e.oldIndex, e.newIndex)}
                            useDragHandle
                            distance={2}
                            lockAxis="y"
                          />
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add Option
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </div>

                  <div className="col-6 mt-3">
                    <Form.Item
                      name="discountvalue"
                      label={formatMessage({ id: 'form.label.discount' })}
                      rules={[{ required: true, message: 'Missing Discount' }]}
                    >
                      <InputNumber
                        style={{ width: '250px' }}
                        size="large"
                        min={0}
                        placeholder="Discount in INR"
                      />
                    </Form.Item>
                  </div>
                </>
              )}
              <div className="col-6 mt-3">
                <Form.Item
                  name="gst"
                  label={formatMessage({ id: 'form.label.gst' })}
                  rules={[{ required: true, message: 'Missing gst' }]}
                >
                  <Select
                    placeholder={formatMessage({ id: 'form.placeholder.gst' })}
                    size="large"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    return (
                    <Option key="interstate" value="interstate">
                      Interstate - IGST
                    </Option>
                    <Option key="intrastate" value="intrastate">
                      Intrastate - SGST/CGST
                    </Option>
                    )
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="row">
              {showcgst === 1 && (
                <>
                  <div className="col-6 mt-3">
                    <Form.Item
                      name="cgst"
                      label={formatMessage({ id: 'form.label.cgst' })}
                      rules={[{ required: true, message: 'Missing CGST' }]}
                    >
                      <InputNumber
                        size="large"
                        min={0}
                        max={18}
                        style={{ width: '250px' }}
                        placeholder="CGST %"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-6 mt-3">
                    <Form.Item
                      name="sgst"
                      label={formatMessage({ id: 'form.label.sgst' })}
                      rules={[{ required: true, message: 'Missing SGST' }]}
                    >
                      <InputNumber
                        size="large"
                        min={0}
                        max={18}
                        style={{ width: '250px' }}
                        placeholder="SGST %"
                      />
                    </Form.Item>
                  </div>
                </>
              )}
              {showigst === 1 && (
                <>
                  <div className="col-6 mt-3">
                    <Form.Item
                      name="igst"
                      label={formatMessage({ id: 'form.label.igst' })}
                      rules={[{ required: true, message: 'Missing IGST' }]}
                    >
                      <InputNumber
                        size="large"
                        min={0}
                        max={18}
                        style={{ width: '250px' }}
                        placeholder="IGST %"
                      />
                    </Form.Item>
                  </div>
                </>
              )}

              <div className="col-12 mt-3">
                <Form.Item
                  name="keynotes"
                  label={formatMessage({ id: 'form.label.keynotes' })}
                  rules={[{ required: true, message: 'Missing keynotes' }]}
                >
                  <TextArea
                    size="large"
                    style={{ width: '650px', resize: 'none' }}
                    rows="5"
                    placeholder="keynotes"
                  />
                </Form.Item>
              </div>

              <div className="col-12 mt-3">
                <Form.Item className="float-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={singleaccounts?.creating}
                  >
                    Create
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(CreateItem))
