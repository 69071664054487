import { createSelector } from 'reselect'

export const selectSingleAccounts = state => state?.singleaccounts

export const selectQuotationInfo = createSelector([selectSingleAccounts], singleaccounts => {
  const { searchsinglequotation, getsinglebusinessquotation } = singleaccounts
  if (searchsinglequotation && getsinglebusinessquotation) {
    return getsinglebusinessquotation.filter(
      r =>
        r?.business_name?.toLowerCase().includes(searchsinglequotation.toLowerCase()) ||
        r?.mobile?.toLowerCase().includes(searchsinglequotation.toLowerCase()) ||
        r?.emailid?.toLowerCase().includes(searchsinglequotation.toLowerCase()) ||
        r?.billamount?.toLowerCase().includes(searchsinglequotation.toLowerCase()),
    )
  }
  return getsinglebusinessquotation
})

export const selectTodayFollowup = createSelector([selectSingleAccounts], singleaccounts => {
  const { search, followupList } = singleaccounts
  if (search && followupList) {
    return followupList.filter(
      r =>
        r?.business_name?.toLowerCase().includes(search.toLowerCase()) ||
        r?.mobile?.toLowerCase().includes(search.toLowerCase()) ||
        r?.emailid?.toLowerCase().includes(search.toLowerCase()),
    )
  }
  return followupList
})

export const selectUpcomingFollowup = createSelector([selectSingleAccounts], singleaccounts => {
  const { search, followupList } = singleaccounts
  if (search && followupList) {
    return followupList.filter(
      r =>
        r?.business_name?.toLowerCase().includes(search.toLowerCase()) ||
        r?.mobile?.toLowerCase().includes(search.toLowerCase()) ||
        r?.emailid?.toLowerCase().includes(search.toLowerCase()),
    )
  }
  return followupList
})

export const selectClosedFollowup = createSelector([selectSingleAccounts], singleaccounts => {
  const { search, followupList } = singleaccounts
  if (search && followupList) {
    return followupList.filter(
      r =>
        r?.business_name?.toLowerCase().includes(search.toLowerCase()) ||
        r?.mobile?.toLowerCase().includes(search.toLowerCase()) ||
        r?.emailid?.toLowerCase().includes(search.toLowerCase()),
    )
  }
  return followupList
})
