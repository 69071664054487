const actions = {
  SET_STATE: 'accounts/SET_STATE',
  LOAD_ALL: 'accounts/LOAD_ALL',
  SHOW_UPDATE: 'accounts/SHOW_UPDATE',
  HIDE_UPDATE: 'accounts/HIDE_UPDATE',
  SHOW_NOTES: 'accounts/SHOW_NOTES',
  HIDE_NOTES: 'accounts/HIDE_NOTES',
  SHOW_ACCOUNT_MANAGER: 'accounts/SHOW_ACCOUNT_MANAGER',
  HIDE_ACCOUNT_MANAGER: 'accounts/HIDE_ACCOUNT_MANAGER',
  GET_LEAD_OWNERS: 'accounts/GET_LEAD_OWNERS',
  CHANGE_ACCOUNT_MANAGER: 'accounts/CHANGE_ACCOUNT_MANAGER',
  SHOW_ASSIGNEDTO: 'accounts/SHOW_ASSIGNEDTO',
  HIDE_ASSIGNEDTO: 'accounts/HIDE_ASSIGNEDTO',
  CHANGE_ACCOUNTS_ASSIGNED_TO: 'accounts/CHANGE_ACCOUNTS_ASSIGNED_TO',

  UPDATE_ACCOUNTS: 'accounts/UPDATE_ACCOUNTS',
  GETACCOUNTSNOTES: 'accounts/GETACCOUNTSNOTES',
  SAVEACCOUNTNOTES: 'accounts/SAVEACCOUNTNOTES',
  GETFILTER: 'accounts/GETFILTER',
}

export default actions
