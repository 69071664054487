import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import SingleAccountsAction from 'redux/singleaccounts/actions'
import { Tabs } from 'antd'
import { Link } from 'react-router-dom'
import ViewQuotation from './ViewQuotation'
import AccountDetail from './AccountDetail'
import NotesInfo from './NotesInfo'
import ViewDesign from './Design'
import ProformaInv from './Proforma'

const { TabPane } = Tabs

const mapStateToProps = ({ singleaccounts }) => ({
  singleaccounts,
})

const QuotationList = ({ singleaccounts, intl: { formatMessage } }) => {
  const dispatch = useDispatch()

  const path = window.location.pathname
  const idval = path.replace('/manage/accounts/', '')

  useEffect(() => {
    if (idval !== null)
      dispatch({
        type: SingleAccountsAction.ACCOUNT_DETAIL,
        payload: idval,
      }) // eslint-disable-next-line
  }, [dispatch])

  const query = new URLSearchParams(window.location.search)
  const tabname = query.get('tab')

  let tabUrlVal

  if (tabname === 'accounts-info') {
    tabUrlVal = '1'
  } else if (tabname === 'notes') {
    tabUrlVal = '2'
  } else if (tabname === 'quotation') {
    tabUrlVal = '3'
  } else if (tabname === 'design') {
    tabUrlVal = '4'
  } else if (tabname === 'proforma') {
    tabUrlVal = '5'
  }

  return (
    <div>
      <Helmet title={formatMessage({ id: 'title.helmet.Quotation' })} />
      <div className="card">
        <div className="card-body">
          <h5 className="mb-4">
            <strong>{singleaccounts?.accountDetailInfo?.business_name}</strong>
          </h5>
          <Tabs defaultActiveKey={tabUrlVal}>
            <TabPane
              tab={
                <Link to="?tab=accounts-info">
                  <span>{formatMessage({ id: 'text.tab.accountsinfo' })}</span>
                </Link>
              }
              key={1}
            >
              <AccountDetail selectedAccountsData={singleaccounts?.accountDetailInfo} />
            </TabPane>
            <TabPane
              tab={
                <Link to="?tab=notes">
                  <span>{formatMessage({ id: 'text.tab.notes' })}</span>
                </Link>
              }
              key={2}
            >
              <NotesInfo />
            </TabPane>
            <TabPane
              tab={
                <Link to="?tab=quotation">
                  <span>{formatMessage({ id: 'text.tab.quotation' })}</span>
                </Link>
              }
              key={3}
            >
              <ViewQuotation />
            </TabPane>
            <TabPane
              tab={
                <Link to="?tab=design">
                  <span>{formatMessage({ id: 'text.tab.design' })}</span>
                </Link>
              }
              key={4}
            >
              <ViewDesign />
            </TabPane>
            <TabPane
              tab={
                <Link to="?tab=proforma">
                  <span>{formatMessage({ id: 'text.tab.proforma' })}</span>
                </Link>
              }
              key={5}
            >
              <ProformaInv />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(QuotationList))
