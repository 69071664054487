import React from 'react'
import { sortableHandle } from 'react-sortable-hoc'
import { MenuOutlined } from '@ant-design/icons'
import styles from './style.module.scss'

const DragHandle = sortableHandle(() => {
  return (
    <div className={styles.dragHandle}>
      <MenuOutlined />
    </div>
  )
})

export default DragHandle
