import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadallproducts() {
  return apiClient
    .get(`${BASE_URL}/getallproducts`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveproduct(payload) {
  return apiClient
    .post(`${BASE_URL}/saveproductinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function updateproduct(payload) {
  return apiClient
    .post(`${BASE_URL}/updateproductinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getspecification(payload) {
  return apiClient
    .post(`${BASE_URL}/getspecificationinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
