import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadallquotation() {
  return apiClient
    .get(`${BASE_URL}/getallquotation`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadquotation(payload) {
  return apiClient
    .post(`${BASE_URL}/getquotation`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}



export async function getspecificationinfo(payload) {
  return apiClient
    .post(`${BASE_URL}/getspecification`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function downloadpdf(payload) {
  return apiClient
    .get(`${BASE_URL}/exportquotation/${payload?.QuotationId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function savequotation(payload) {
  return apiClient
    .post(`${BASE_URL}/savequotationdata`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function savefollowup(payload) {
  return apiClient
    .post(`${BASE_URL}/savefollowup`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deletequotation(payload) {
  return apiClient
    .post(`${BASE_URL}/deletequotation`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatequotation(payload) {
  return apiClient
    .post(`${BASE_URL}/updatequotationdata`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadproductinfo(payload) {
  return apiClient
    .post(`${BASE_URL}/getproductlist`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
