import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadall, loadinvoicedetail, loadpaymentdetail, createItem } from 'api/invoice'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadall)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoicelist: response?.data?.invoicelistinfo,
      },
    })
  }
}

export function* LOAD_INVOICE_DETAIL({ payload }) {
  const { response } = yield call(loadinvoicedetail, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoicedetail: response?.data?.invoicedetailinfo,
      },
    })
  }
}

export function* LOAD_PAYMENT_DETAIL({ payload }) {
  const { response } = yield call(loadpaymentdetail, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        paymentdetail: response?.data?.paymentdetailinfo[0],
      },
    })
  }
}

export function* CREATE_INVOICE_ITEM({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(createItem, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayCreateInvoiceDrawer: false,
        invoicelist: response?.data?.invoicelistinfo,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.LOAD_INVOICE_DETAIL, LOAD_INVOICE_DETAIL),
    takeLatest(actions.LOAD_PAYMENT_DETAIL, LOAD_PAYMENT_DETAIL),
    takeLatest(actions.CREATE_INVOICE_ITEM, CREATE_INVOICE_ITEM),
  ])
}
