import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadallleads() {
  return apiClient
    .get(`${BASE_URL}/getleads`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function leadfilter() {
  return apiClient
    .get(`${BASE_URL}/getleadfilter`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function leadcity() {
  return apiClient
    .get(`${BASE_URL}/getleadcity`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function leadproducts() {
  return apiClient
    .get(`${BASE_URL}/getleadproducts`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getmyproducts() {
  return apiClient
    .get(`${BASE_URL}/getmyproducts`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadfilteredleads(payload) {
  return apiClient
    .post(`${BASE_URL}/getfilteredleads`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadexportleads(payload) {
  return apiClient
    .post(`${BASE_URL}/loadexportleads`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadleadnotes(payload) {
  return apiClient
    .get(`${BASE_URL}/getleadnoteinfo/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function converttoaccounts(payload) {
  return (
    apiClient
      .put(`${BASE_URL}/convertleadtoaccountsinfo/`, payload)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  )
}

export async function saveleadnote(payload) {
  return apiClient
    .post(`${BASE_URL}/saveleadnoteinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadleadowners() {
  return apiClient
    .get(`${BASE_URL}/getleadowners`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveleads(payload) {
  return apiClient
    .post(`${BASE_URL}/saveleadinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editleads(_id, payload) {
  return apiClient
    .put(`${BASE_URL}/editleadinfo/${_id}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changeleadowner(payload) {
  console.log('api', payload)
  return apiClient
    .post(`${BASE_URL}/changeleadowner`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteleads(_id) {
  return apiClient
    .put(`${BASE_URL}/deleteleadinfo/${_id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
