const actions = {
  SET_STATE: 'leads/SET_STATE',
  SHOW_MODAL: 'leads/SHOW_MODAL',
  HIDE_MODAL: 'leads/HIDE_MODAL',
  SHOW_CREATE: 'leads/SHOW_CREATE',
  HIDE_CREATE: 'leads/HIDE_CREATE',
  SHOW_UPDATE: 'leads/SHOW_UPDATE',
  HIDE_UPDATE: 'leads/HIDE_UPDATE',
  SHOW_DELETE: 'leads/SHOW_DELETE',
  HIDE_DELETE: 'leads/HIDE_DELETE',
  SHOW_LEAD_OWNER: 'leads/SHOW_LEAD_OWNER',
  HIDE_LEAD_OWNER: 'leads/HIDE_LEAD_OWNER',
  GET_LEAD_OWNERS: 'leads/GET_LEAD_OWNERS',
  CHANGE_LEAD_OWNER: 'leads/CHANGE_LEAD_OWNER',
  REMOVE: 'leads/REMOVE',
  SHOW_NOTES: 'leads/SHOW_NOTES',
  HIDE_NOTES: 'leads/HIDE_NOTES',
  GETLEADNOTES: 'leads/GETLEADNOTES',
  SAVELEADNOTE: 'leads/SAVELEADNOTE',
  SHOW_ACCOUNTS_CONVERT: 'leads/SHOW_ACCOUNTS_CONVERT',
  HIDE_ACCOUNTS_CONVERT: 'leads/HIDE_ACCOUNTS_CONVERT',
  CONVERT_ACCOUNTS: 'leads/CONVERT_ACCOUNTS',
  LOAD_LEAD_FILTER: 'leads/LOAD_LEAD_FILTER',
  GET_LEAD_CITY: 'leads/GET_LEAD_CITY',
  GET_LEAD_PRODUCTS: 'leads/GET_LEAD_PRODUCTS',
  EXPORT_LEADS: 'leads/EXPORT_LEADS',

  LOAD_ALL: 'leads/LOAD_ALL',
  LOAD_MY_PRODUCTS: 'leads/LOAD_MY_PRODUCTS',
  ADD_LEADS: 'leads/ADD_LEADS',
  UPDATE_LEADS: 'leads/UPDATE_LEADS',
  GETFILTER: 'leads/GETFILTER',
}

export default actions
