/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  invoicelist: null,
  paymentdetail: null,
  invoicedetail: null,
  selectedData: null,
  searchinvoice: null,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
  displayProformaInvoiceDrawer: false,
  displayInvoiceDrawer: false,
  displayCreateInvoiceDrawer: false,
}

export default function invoiceReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.VIEW_INVOICES:
      return { ...state, selectedData: action.payload, displayInvoiceDrawer: true }
    case actions.HIDE_INVOICES:
      return { ...state, selectedData: null, displayInvoiceDrawer: false }
    case actions.VIEW_PO:
      return { ...state, selectedData: action.payload, displayProformaInvoiceDrawer: true }
    case actions.HIDE_PO:
      return { ...state, selectedData: null, displayProformaInvoiceDrawer: false }
    case actions.ADD_ITEM:
      return { ...state, selectedData: action.payload, displayCreateInvoiceDrawer: true }
    case actions.HIDE_ITEM:
      return { ...state, selectedData: null, displayCreateInvoiceDrawer: false }

    default:
      return state
  }
}
