/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  accountDetailInfo: null,
  followupList: null,
  getsinglebusinessquotation: null,
  searchsinglequotation: null,
  designInfo: null,
  searchinvoice: null,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
  displayUploadDesign: false,
  displayUploadedImage: false,
  displayDeleteImagePopup: false,
  displayCreateInvoiceDrawer: false,
  displayFollowupModal: false,
}

export default function singleaccountsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayUploadDesign: true }
    case actions.HIDE_CREATE:
      return { ...state, displayUploadDesign: false }

    case actions.SHOW_POPUP:
      return { ...state, selectedData: action.payload, displayUploadedImage: true }
    case actions.HIDE_POPUP:
      return { ...state, displayUploadedImage: false }

    case actions.DELETE_SHOW_IMAGE:
      return { ...state, selectedData: action.payload, displayDeleteImagePopup: true }
    case actions.DELETE_HIDE_IMAGE:
      return { ...state, displayDeleteImagePopup: false }
    case actions.ADD_ITEM:
      return { ...state, selectedData: action.payload, displayCreateInvoiceDrawer: true }
    case actions.HIDE_ITEM:
      return { ...state, selectedData: null, displayCreateInvoiceDrawer: false }

    case actions.SHOW_FOLLOWUP:
      return { ...state, selectedData: action.payload, displayFollowupModal: true }
    case actions.HIDE_FOLLOWUP:
      return { ...state, displayFollowupModal: false }

    default:
      return state
  }
}
