import React from 'react'
import { connect } from 'react-redux'
import Loading from 'components/app/common/Loading'

const mapStateToProps = ({ quotation, dispatch }) => ({
  quotation,
  dispatch,
})

const SpecificationComponent = ({ quotation }) => {
  if (!quotation?.productlistInfo) return <Loading />

  const SpecArray = quotation?.specificationInfo

  return (
    <>
      {quotation?.productlistInfo?.map(plist => (
        <>
          <div className="card">
            <div className="card-body">
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td colSpan="10">
                      <h4> {plist?.product_name}</h4>
                    </td>
                  </tr>

                  {/* {SpecArray?.filter(
                    data => data?.quotation_number === quotation?.selectedData?.quotation_number,
                  ).map(typeinfo => ( */}
                  {SpecArray?.map(typeinfo => (
                    <>
                      <tr>
                        <td colSpan="10" rowSpan="1">
                          <>
                            <div className="row">
                              <div className="col-12">
                                <div className="font-size-14">
                                  <span>
                                    <b style={{ textTransform: 'capitalize' }}>{typeinfo?.type}</b>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        </td>
                        <td colSpan="10" rowSpan="1">
                          <>
                            <div className="row">
                              <div className="col-12">
                                <div className="font-size-14">
                                  <span>{typeinfo?.briefdescription}</span>
                                </div>
                              </div>
                            </div>
                          </>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ))}
    </>
  )
}

export default connect(mapStateToProps)(SpecificationComponent)
