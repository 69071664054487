import React, { useState, Suspense, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import { Input, Table, Badge, Form, Spin, Space, Drawer } from 'antd'
import { createStructuredSelector } from 'reselect'
import { phoneNumberNational } from 'utils/parser'
import { selectSingleAccounts, selectQuotationInfo } from 'redux/singleaccounts/selectors'
import { SelectQuotation } from 'redux/quotation/selectors'
import { selectSettings } from 'redux/settings/selectors'
import { selectUser } from 'redux/user/selectors'
import QuotationAction from 'redux/quotation/actions'
import SingleAccountsAction from 'redux/singleaccounts/actions'
import SpecificationInfo from '../SpecificationInfo'

const { Search } = Input

const mapStateToProps = () =>
  createStructuredSelector({
    singleAccount: selectSingleAccounts,
    selectedSingleQuotation: selectQuotationInfo,
    settings: selectSettings,
    quotation: SelectQuotation,
    user: selectUser,
  })

const ViewQuotationComponent = ({
  quotation,
  selectedSingleQuotation,
  settings,
  user,
  intl: { formatMessage },
}) => {
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const path = window.location.pathname
  const idval = path.replace('/manage/accounts/', '')

  useEffect(() => {
    if (idval !== null)
      dispatch({
        type: SingleAccountsAction.LOAD_SINGLE_QUOTATION,
        payload: idval,
      }) // eslint-disable-next-line
  }, [dispatch])

  const onSearch = value => {
    dispatch({
      type: SingleAccountsAction.SET_STATE,
      payload: { searchsinglequotation: value },
    })
  }

  const showSpecifications = value => {
    dispatch({
      type: QuotationAction.GET_SPECIFICATION,
      payload: { QuotationId: value?.quotation_number },
    })
    dispatch({
      type: QuotationAction.LOAD_PRODUCT_INFO,
      payload: {
        QuotationId: value?.quotation_number,
      },
    })
  }

  const hideSpecification = () => {
    dispatch({
      type: QuotationAction.HIDE_SPECIFICATION,
    })
  }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.QuotationData' }),
  }

  const exportPdf = value => {
    const QuotationId = value?.quotation_number
    const apiUrl = 'https://aceautomation.acculermedia.com/Login/exportquotation'
    window.open(
      `${apiUrl}?QuotationId=${QuotationId}&userId=${user?.userId}&accessToken=${user?.accessToken}`,
    )
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.businessName' }),
      key: 'business_name',
      render: record => {
        return (
          <>
            <span>{`${record?.business_name} ->`}</span>
            <a href="javascript:void(0)" style={{ color: '#F26E1C', fontWeight: '600' }}>
              {record.quotation_number}
            </a>
          </>
        )
      },
    },
    {
      title: formatMessage({ id: 'text.email' }),
      dataIndex: 'emailid',
      key: 'emailid',
    },
    {
      title: formatMessage({ id: 'text.mobile' }),
      dataIndex: 'mobile',
      key: 'mobile',
      render: text => {
        let formattedPhoneNumber = '-'
        const phoneNumber = phoneNumberNational(text)
        if (phoneNumber) formattedPhoneNumber = phoneNumber
        return <span>{formattedPhoneNumber}</span>
      },
    },
    {
      title: formatMessage({ id: 'text.bill_amount' }),
      dataIndex: 'billamount',
      key: 'billamount',
      render: text => {
        return <span>{`${text} INR`}</span>
      },
    },
    {
      title: formatMessage({ id: 'text.createddate' }),
      dataIndex: ['created_date_time'],
      key: 'created_date_time',
      render: text => moment(text).format('MMM D, YYYY HH:mm:ss A'),
    },
    {
      title: formatMessage({ id: 'text.status' }),
      dataIndex: 'status',
      key: 'status',
      render: text => (
        <>
          {text === '1' && (
            <>
              <Badge color="green" text={formatMessage({ id: 'text.active' })} />
            </>
          )}
          {text === '0' && (
            <>
              <Badge color="red" text={formatMessage({ id: 'text.inactive' })} />
            </>
          )}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.action' }),
      key: 'action',
      render: (record, text) => (
        <Space size="middle">
          <button type="button" className="btn btn-light" onClick={() => showSpecifications(text)}>
            <i className="fe fe-eye mr-1" aria-hidden />
            {formatMessage({ id: 'action.viewSpecifications' })}
          </button>
          <button type="button" className="btn btn-light" onClick={() => exportPdf(text)}>
            <i className="fe fe-download mr-1" aria-hidden />
            {formatMessage({ id: 'action.exportPdf' })}
          </button>
        </Space>
      ),
    },
  ]

  return (
    <div className="mb-4">
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              style={{ width: '100%' }}
              placeholder={formatMessage({ id: 'form.user.placeholder.searchQuotation' })}
              size="large"
              onChange={e => onSearch(e.target.value)}
              allowClear
            />
          </Form.Item>
        </div>
      </div>
      <div className="kit__utils__table">
        <Suspense fallback={<Spin />}>
          {selectedSingleQuotation?.length >= 0 ? (
            <Table
              dataSource={selectedSingleQuotation}
              columns={columns}
              locale={locale}
              rowKey="_id"
              pagination={{
                onChange(current) {
                  setPage(current)
                },
              }}
            />
          ) : (
            <Table
              columns={columns}
              locale={locale}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
              }}
            />
          )}
        </Suspense>
      </div>

      <Drawer
        title={formatMessage({ id: 'text.Specifications' })}
        placement="right"
        destroyOnClose
        visible={quotation.displaySpecificationDrawer}
        onClose={hideSpecification}
        width={settings.isMobileView ? '100%' : '44%'}
      >
        <SpecificationInfo />
      </Drawer>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ViewQuotationComponent))
