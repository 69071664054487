import { createSelector } from 'reselect'

export const SelectQuotation = state => state?.quotation

export const selectedQuotationData = createSelector([SelectQuotation], quotation => {
  const { searchquotation, quotationlistinfo } = quotation
  if (searchquotation && quotationlistinfo) {
    return quotationlistinfo.filter(
      r =>
        r?.business_name?.toLowerCase().includes(searchquotation.toLowerCase()) ||
        r?.contact_name?.toLowerCase().includes(searchquotation.toLowerCase()) ||
        r?.quotation_number?.toLowerCase().includes(searchquotation.toLowerCase()) ||
        r?.emailid?.toLowerCase().includes(searchquotation.toLowerCase()),
    )
  }
  return quotationlistinfo
})

export const selectedSingleBusinessQuotationData = createSelector([SelectQuotation], quotation => {
  const { searchquotation, getsinglebusiness } = quotation
  if (searchquotation && getsinglebusiness) {
    return getsinglebusiness.filter(
      r =>
        r?.business_name?.toLowerCase().includes(searchquotation.toLowerCase()) ||
        r?.contact_name?.toLowerCase().includes(searchquotation.toLowerCase()) ||
        r?.emailid?.toLowerCase().includes(searchquotation.toLowerCase()),
    )
  }
  return getsinglebusiness
})