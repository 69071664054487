export default async function getMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-user',
      url: '/dashboard',
    },
    {
      title: 'Manage Leads',
      key: 'manage-leads',
      icon: 'fe fe-user',
      url: '/manage/leads',
    },
    {
      title: 'Manage Accounts',
      key: 'manage-accounts',
      icon: 'fe fe-file',
      url: '/manage/accounts',
    },
    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-user',
      url: '/manage/user',
    },
    {
      title: 'Manage Products',
      key: 'manage-product',
      icon: 'fe fe-download',
      url: '/manage/product',
    },
    {
      title: 'Manage Quotation',
      key: 'manage-quotation',
      icon: 'fe fe-download',
      url: '/manage/quotation',
    },
  ]
}
